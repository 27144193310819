import './App.css';

function App() {
  return (
    <div class="logo-container">
      <img src="/logo.png" />
    </div>
  );
}

export default App;
